import React, { useState } from "react";
import {
  StyledVideoReviews,
  StyledVideoReviewsItem,
  StyledVideoReviewsItemTitle,
  StyledVideoReviewsItemVideo,
  StyledVideoReviewsSwiper,
} from "./style";
import VimeoPlayer from "../VimeoPlayer/VimeoPlayer";
import { StyledGridRow } from "../../commons/Grid";
import Intro from "../Intro_v2/Intro_v2";
import { Button } from "@3beehivetech/components";
import useNavigate from "../../hooks/useNavigate";

export default ({
  intro, videos, themeColor, cta, 
}) => {
  const [currentVideo, setCurrentVideo] = useState(null);

  return (
    <StyledVideoReviews id="beekeeper">
      <Intro {...intro} themeColor={themeColor} />
      <StyledGridRow noMargin isFullMobile>
        <StyledVideoReviewsSwiper>
          {videos.map((video, index) => {
            return (
              <StyledVideoReviewsItem key={index}>
                <StyledVideoReviewsItemVideo
                  onClick={() => {
                    if (currentVideo !== index && currentVideo !== false) {
                      setCurrentVideo(index);
                    }
                  }}
                >
                  <VimeoPlayer
                    videoID={video.videoID}
                    thumb={video.thumb ?? video.image}
                    play={currentVideo === index}
                    height={180}
                    setPlay={setCurrentVideo}
                    playControl={true}
                  />
                </StyledVideoReviewsItemVideo>
                <StyledVideoReviewsItemTitle>
                  {video.title}
                </StyledVideoReviewsItemTitle>
              </StyledVideoReviewsItem>
            );
          })}
        </StyledVideoReviewsSwiper>
        {cta && (
          <div style="display: flex; justify-content: center; padding-top: 80px;">
            <Button onClick={useNavigate(cta.url)}>{cta.text}</Button>
          </div>
        )}
      </StyledGridRow>
    </StyledVideoReviews>
  );
};
