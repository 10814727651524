import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";
import { StyledVimeoPlayer } from "../VimeoPlayer/style";
import { StyledIcon } from "../Icon/style";

export const StyledVideoReviews = styled.div`
`;

export const StyledVideoReviewsSwiper = styled.div`
  display: flex;
  gap: 15px 25px;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  margin-left: 10px;
  @media (${BP.ipad}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const StyledVideoReviewsControl = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: ${Rem(52)};
  height: ${Rem(52)};
  top: 50%;
  left: 0;
  transform: translateY(-100%);
  background-color: ${Colors.yellow};
  z-index: 1;
  cursor: pointer;

  ${StyledIcon} {
    width: ${Rem(8)};
  }

  @media (${BP.desktop}) {
    display: flex;
    left: ${Rem(-16)};

    ${(props) =>
    props.next
    && css`
        left: auto;
        right: ${Rem(-16)};
      `}
  }
`;

export const StyledVideoReviewsItem = styled.div`
  min-width: 300px;
`;

export const StyledVideoReviewsItemVideo = styled.div`
  position: relative;
  ${Ratio(586, 326)};
  width: 100%;
  cursor: pointer;

  ${StyledVimeoPlayer} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StyledVideoReviewsItemTitle = styled.div`
  width: 100%;
  padding-bottom: 10px;
  font-size: ${Rem(20)};
  letter-spacing: ${Rem(-0.5)};
  margin-top: ${Rem(20)};
  @media (${BP.ipad}) {
    text-align: center;
  }
`;
